@import '../../styles/customMediaQueries.css';

.root {
  display: flex;
  flex-direction: column;
}

.container {
  padding: 2rem 1rem; /* Default padding */
}

/* Media queries for adjusting padding */
@media (max-width: 600px) {
  .container {
    padding: 1.5rem 1rem;
  }
}

@media (min-width: 768px) {
  .container {
    padding: 3rem 2rem;
  }
}

@media (min-width: 1024px) {
  .container {
    padding: 4rem 3rem;
  }
}

@media (min-width: 1440px) {
  .container {
    padding: 5rem 4rem;
  }
}

.h2 {
    composes: h2 from global;
  }

.latestListingsContainer {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(250px, 1fr)); /* Adjust the minmax values as per your design */
    gap: 16px; /* Space between cards */
    padding: 16px; /* Adjust padding as needed */
    margin: 0 auto; /* Centers the grid in the page */

    /* Optional: Set a max-width if you want to limit the width of the grid */
    max-width: 1200px; /* Adjust or remove as needed */
}

.latestListingsHeader {
    text-align: center; /* Center the header text */
    margin-bottom: 30px;
    margin-top: 0;
    line-height: 1.33;
    color: var(--colorGrey900);
    word-break: break-all;
    word-break: break-word;
    -webkit-hyphens: auto;
    hyphens: auto;
    @media (min-width: 1440px) {
        font-size: 50px;
    }
}

.listingCard {
    width: 100%;
}

.listingCards {
    padding: 0 10px 30px 10px;

    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 24px;
    width: 100%;
  
    @media (min-width: 550px) {
      grid-template-columns: repeat(2, 1fr);
    }
  
    @media (--viewportMedium) {
      padding: 0 10px 96px 10px;
      grid-template-columns: repeat(2, 1fr);
    }
  
    @media (--viewportLarge) {
      grid-template-columns: repeat(5, 1fr);
      padding: 0 20px 30px 20px;
    }
  
    @media (min-width: 1440px) {
      grid-template-columns: repeat(5, 1fr);
      padding: 0 30px 72px 30px;
    }
  
    @media (--viewportXLarge) {
      grid-template-columns: repeat(5, 1fr);
      padding: 0 50px 72px 50px;
    }
  }
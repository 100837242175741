.isDragging .carouselWrapper {
  pointer-events: all;
}

.titleContainer {
  display: flex;
  align-items: baseline;
  justify-content: space-between; /* Ensures the title is on the left and See More on the right */
  margin-bottom: 10px;
}

.titleContainer a{
  margin-right: 10px;
}

.similarListingsTitle {
  font-size: 24px;
  margin: 0;
  flex: 1; /* Ensures it takes up space on the left */
  text-align: left;
}
.similarListingsTitleEvent{
  margin: 0;
  flex: 1; /* Ensures it takes up space on the left */
  text-align: left;
}
.seeMoreLink {
  color: var(--marketplaceColor);
  text-decoration: none;
  flex-shrink: 0; /* Prevents the link from shrinking */
  font-weight: bold;
}

.seeMoreLink:hover {
  text-decoration: underline;
}


.similarListingsSection {
  overflow: hidden;
  width: 100%;
  max-width: 100%;
  position: relative; /* Add this */
}

.carouselContainer {
  display: flex;
  align-items: center;
  position: relative;
  width: 100%;
  padding: 0 40px; /* Add padding to make room for buttons */
}

.carouselWrapper {
  overflow-x: auto;
  overflow-y: hidden;
  scroll-behavior: smooth;
  -webkit-overflow-scrolling: touch;
  scrollbar-width: none;
  -ms-overflow-style: none;
  cursor: grab;
  width: 100%;
}

.carouselWrapper::-webkit-scrollbar { 
  display: none;
}

.carouselWrapper:active {
  cursor: grabbing;
}

.carouselTrack {
  display: flex;
  padding-bottom: 20px;
  width: 100%;
}

.carouselItem {
  flex-shrink: 0;
  padding: 0 10px;
  box-sizing: border-box;
}

.similarListingsTitle {
  font-size: 24px;
  font-weight: bold;
  padding-left: 10px;
  margin: 0 0 20px 0;
  width: 100%;
}

.similarListingsTitleEvent{
  color: var(--colorGrey900);
  margin: 0 0 20px 0;
  width: 100%;
  padding-left:10px;
  @media(max-width: 768px){
    padding-left: 0px;
  }
  @media(min-width: 1024px){
    padding-left: 20px;
    margin-top: 30px;
  }
}

.carouselButton {
  background: #f0f0f0;
  border: none;
  padding: 10px 15px;
  cursor: pointer;
  font-size: 18px;
  z-index: 1;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}

.carouselButton:first-child {
  left: 0;
}

.carouselButton:last-child {
  right: 0;
}

.carouselButton:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

@media (max-width: 768px) {
  .carouselWrapper {
    overflow-x: scroll;
    -webkit-overflow-scrolling: touch;
    scroll-snap-type: x mandatory;
  }

  .carouselItem {
    scroll-snap-align: start;
  }

  .similarListingsSection {
    margin-top: 20px;
  }

  .similarListingsTitle {
    margin: 5px 5px 10px 5px;
  }

  .similarListingsTitleEvent{
    margin: 5px 5px 10px 5px;
  }

  .carouselButton {
    display: none;
  }

  .carouselItem {
    padding: 0 5px;
  }

  .carouselContainer {
    padding: 0; /* Remove padding on mobile */
  }
}
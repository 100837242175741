.backgroundContainer {
  position: relative;
  width: 100%;
  padding-bottom: 50px; /* Add space for additional elements */
  background: linear-gradient(135deg, hsl(357, 78%, 77%), hsl(356, 58%, 55%),	hsl(354, 82%, 59%)); /* Gradient background */
  overflow: hidden;
  color: rgb(254,253,249);
}

.backgroundImage {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1; /* Ensure it's behind everything else */
  background-image: url('./assets/Bgimage.png'); /* Replace with your image path */
  background-size: cover; /* Ensures the image covers the entire container */
  background-position: center; /* Center the image */
  opacity: 0.11;/* Set desired opacity for the image */
  pointer-events: none; /* Prevent interaction with the background */
}

.overlayContent {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
}
.backgroundContainer {
  display: flex;
  justify-content: center; /* Center the content */
  align-items: center;
  flex-wrap: wrap; /* Wrap for mobile view */
  width: 100%;
  background: linear-gradient(135deg, hsl(357, 78%, 77%), hsl(356, 58%, 55%),	hsl(354, 82%, 59%)); /* Background gradient */
  position: relative;
  padding: 20px; /* Adjust spacing around content */
  overflow: hidden;
}

/* Overlay Content: Images and Text */
.overlayContent {
  display: flex;
  justify-content: space-between; /* Align images and text */
  align-items: center;
  width: 100%;
  max-width: 1200px; /* Center content for larger screens */
  flex-wrap: wrap; /* Allow wrapping for mobile */
}

.vShapeContainer {
  margin-top: 10px;
  position: relative;
  width: 100%; /* Full width of the parent container */
  height: 300px; /* Fixed height for consistent positioning */
  display: flex;
  justify-content: center;
  align-items: center;
}


/* Common styles for all images */
.imageTop,
.imageBottom {
  position: absolute;
  width: 120px;
  height: 180px;
  object-fit: cover;
  border: 5px solid white; /* Optional border for styling */
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.3); /* Optional shadow */
  border-radius: 10px; /* Optional rounded corners */
  transition: transform 0.3s ease; /* Hover effect */
}

/* Top-left image */
.imageTop:nth-child(1) {
  top: 5%; /* 5% from the top of the container */
  left: 10%; /* 10% from the left of the container */
  z-index: 2; /* Ensure it overlaps properly */
}

/* Top-right image */
.imageTop:nth-child(2) {
  top: 5%; /* 5% from the top of the container */
  right: 10%; /* 10% from the right of the container */
  z-index: 2; /* Ensure it overlaps properly */
}

/* Bottom-center image */
.imageBottom {
  bottom: 5%; /* 5% from the bottom of the container */
  left: 50%; /* Center horizontally */
  transform: translateX(-50%); /* Adjust to center it */
  z-index: 3; /* Bring it above other images */
}

/* Responsive adjustments */
@media (min-width: 1024px){
  .vShapeContainer{
    display:none;
  }
}
@media (max-width: 1024px) {
  .imageTop,
  .imageBottom {
    width: 120px; /* Smaller size for mobile */
    height: 180px;
  }

  .imageTop:nth-child(1) {
    top: 5%; /* Adjust top spacing for mobile */
    left: 5%; /* Adjust left spacing for mobile */
  }

  .imageTop:nth-child(2) {
    top: 5%; /* Adjust top spacing for mobile */
    right: 5%; /* Adjust right spacing for mobile */
  }

  .imageBottom {
    bottom: 5%; /* Adjust bottom spacing for mobile */
    left: 50%; /* Keep centered horizontally */
  }
}


/* Left Images for Desktop */
.imageContainerLeft {
  display: none; /* Hidden on mobile */
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 20px; /* Spacing between images */
  width: 20%; /* Take 20% of width */
}

.imageContainerRight {
  display: none; /* Hidden on mobile */
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 20px; /* Spacing between images */
  width: 20%; /* Take 20% of width */
}

/* Text Section */
.textContainer {
  width: 60%; /* Take 60% of width on desktop */
  text-align: center;
  color: rgb(254,253,249) !important;
}

.textContainer h1 {
  /* font-size: 2rem; */
  margin: 10px 0;
  color: rgb(254,253,249) !important;
}

.textContainer p {
  /* font-size: 1rem; */
  margin: 20px 0;
}

/* Images Common Styles */
.image {
  width: 160px; /* Adjust image size */
  height: 240px;
  position: absolute; /* Position images inside their container */
  object-fit: cover;
  border: 5px solid white;
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.3);
  transition: transform 0.3s ease;
}

/* Left Side Images */
.image1 {
  top: 10%; /* Adjust overlap by changing top and left */
  left: 5%;
  z-index: 1; /* Place it above the second image */
}

.image2 {
  top: 40%; /* Lower than the first image */
  left: 10%; /* Overlaps slightly with the first image */
  z-index: 2; /* Place it behind the first image */
}

/* Right Side Images */
.image3 {
  top: 10%;
  right: 5%; /* Position relative to the container */
  z-index: 1;
}

.image4 {
  top: 40%; /* Lower than the first image */
  right: 10%; /* Overlaps slightly with the first image */
  z-index: 2;
}

/* Mobile Styles */
@media (max-width: 1024px) {
  .overlayContent {
    flex-direction: column; /* Stack text and images */
  }

  .textContainer {
    width: 100%; /* Take full width */
    margin-bottom: 20px;
  }

  .imageContainerLeft,
  .imageContainerRight {
    display: none; /* Show images */
    flex-direction: row; /* Align images in a row */
    justify-content: center;
    width: 100%; /* Take full width */
    gap: 20px; /* Spacing between images */
  }

  .image {
    width: 80px; /* Smaller size for mobile */
    height: 120px;
  }
}

/* Desktop Styles */
@media (min-width: 1024px) {
  .imageContainerLeft,
  .imageContainerRight {
    display: flex; /* Show images */
  }

  .textContainer {
    width: 50%; /* Adjust width for text */
  }
}



.backgroundContainer {
  position: relative;
  width: 100%;
  padding-bottom: 50px; /* Add space for the curve */
  background: linear-gradient(135deg, hsl(357, 78%, 77%), hsl(356, 58%, 55%),	hsl(354, 53%, 46%));
  border-bottom-left-radius: 100px; /* Curved bottom */
  border-bottom-right-radius: 100px;
  overflow: hidden;
  color:rgb(254,253,249);
}


.textImageContainer {
  display: flex;
  flex-direction: column; /* Stacks items vertically by default */
  align-items: center; /* Center items horizontally in mobile view */
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;
  z-index: 1;
  text-align: center;
  padding: 0px;
  color: rgb(254,253,249); /* Ensure text stands out on the gradient */
  
}

.overlayContent {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  height: 100%;
  z-index: 2;
}

.searchBarContainer{
  justify-items: center;
}
.searchBarWrapper{
  width: 70%;
  @media (max-width: 768px) {
    width: 100%;
  }
}

.backgroundVideo {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: -1;
}

/* .overlayContent {
  position: relative;
  z-index: 1;
  color: black; 
  text-align: center;
  padding: 20px;
} */

.browseButton {
  background-color: #ff7b54;
  color: #fff;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.browseButton:hover {
  background-color: #ff5733;
}

.imageContainer {
  margin-top: 20px;
}

/* Container for the text (h1 and p) */
.textContainer {
  flex: 1; /* Allows this section to take up available space */
  text-align: center;
  padding: 10px; /* Add padding for better spacing */
  margin:none;
}

.textContainer h2 {
  /* text-shadow: 3px 3px black; */
}

.textContainer p {
  /* text-shadow: 2px 2px black; */
}



/* Style for the image */
.imageContainer {
  flex: 1; /* Allows this section to take up available space */
  display: flex;
  justify-content: center; /* Center the image horizontally */
  align-items: center;
  padding: 10px;
}

.imageContainer img {
  max-width: 100%; /* Prevents the image from exceeding container width */
  height: auto; /* Maintains aspect ratio */
}

/* Media queries for desktop view */
@media (min-width: 768px) {
  .textImageContainer {
    flex-direction: column; /* Align items side-by-side in desktop view */
    justify-content: space-between; /* Add space between text and image */
    align-items: center; /* Align text and image vertically */
    padding: 20px;
  }

  .textContainer {
    padding-right: 20px; /* Add spacing between text and image */
  }

  .imageContainer {
    padding-left: 20px; /* Add spacing between text and image */
  }
}

.logoContainer {
  display: flex;
  justify-content: center;
  /* margin-bottom: 1rem; */
}

.logo {
  max-width: 50%;
  height: auto;
}


.designImage {
  max-width: 100%;
  /* height: auto; */
  margin-bottom: 10px;
  min-height: 200px; /* Set an initial maximum height */
}

/* Media queries for larger screens */
@media (min-width: 480px) {
  .designImage {
    min-height: 100px; /* Increase the maximum height for screens 480px and wider */
  }
}

@media (min-width: 768px) {
  .designImage {
    min-height: 150px; /* Increase the maximum height for screens 768px and wider */
  }
}

@media (min-width: 992px) {
  .designImage {
    min-height: 200px; /* Increase the maximum height for screens 992px and wider */
  }
}

.designText {
  margin: 0;
}

.container {
  display: flex;
  /* flex-direction: column; */
  justify-content: center;
  align-items: center;
  /* min-height: 75vh; */
  min-height: auto;
  /* position: relative; */
  overflow: hidden;
}

.contentContainer {
  position: flex;
  top: 0;
  left: 0;
  right: 0;
  display: flex;
  flex-direction: column;
  z-index: 1;
  /* padding: 20px; */
  /* text-align: center; */
  color: black;
  width: 100%;
  /* font-style: oblique; */
  /* font-family: ui-sans-serif; */
}

.heading {
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 0;
}

.subheading {
  font-size: 36px;
  font-weight: bold;
  margin-top: 0;
  color: #ff69b4;
}

.description {
  font-size: 16px;
  margin-bottom: 20px;
  font-weight: bolder;
}

.searchContainer {
  position: relative;
  display: inline-block;
  width: 80%;
  padding: 20px;
  background-color: rgb(255, 218, 225);
  border-radius: 20px;
  margin: 20px;
  margin-bottom: 0px;
}

.searchContainer p{
  text-align: left;
  margin-bottom: 0px;
}

.findDessert {
  position: absolute;
  top: -1.5rem;
  left: 50%;
  transform: translateX(-50%);
  margin: 0;
  padding: 0;
}

.findDessert {
  font-size: 18px;
  margin-bottom: 5px;
}

.topbarSearchWithLeftPadding {
  padding-left: 10px;
  border-radius: 25px;
  background-color: rgb(254,253,249);
  z-index: 2;
  /* height: var(--topbarHeightDesktop); */
  height: 50px;
  text-align: center;
  @media (--viewportLarge) {
    padding-left: 34px;
  }
}

.searchInput {
  width: 100%;
  padding: 10px;
  border: none;
  border-radius: 20px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
}

.designContainer {
  display: flex;
  justify-content: center;
  /* flex-wrap: wrap; */
  margin-bottom: 2rem;
}

.designImage {
  max-width: 100%;
  height: auto;
  margin-bottom: 0.5rem;
}

.designText {
  font-size: 14px;
  margin-top: 5px;
}

.buttonContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;
  width: 400px;
}

.button:hover {
  background-color: rgb(255,218,225);
}

.button {
  padding: 10px 5px;
  background-color: rgb(243,68,94);
  color: black;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  white-space: nowrap;
  overflow: hidden; /* This will prevent the text from overflowing the button */
  text-overflow: ellipsis; /* This will add an ellipsis (...) when the text is truncated */
  display: inline-block; /* This will make the buttons equal in size */
  min-width: 120px; /* Set a fixed width for the buttons */
  text-align: center; /* This will center the text within the button */
  font-size: 2rem;
}

/* Media queries for responsive button sizes */
@media (min-width: 768px) {
  .button {
    font-size: 2.5rem;
    min-width: 300px;
  }
}

@media (max-width: 768px) and (min-width: 480px) {
  .button {
    font-size: 1.2rem;
    min-width: 200px;
  }
}

@media (max-width: 480px) {
  .button {
    font-size: 1rem;
  }
}

.browseButton {
  padding: 10px 5px;
  background-color: var(--marketplaceColor);
  color: black;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-weight: bold;
  white-space: nowrap; /* This will prevent the text from wrapping */
  font-size: 1.5rem;
  margin-top: 10px;
  margin-bottom: 10px;
}

@media (max-width: 768px) {
  .browseButton {
    font-size: 1rem;
  }
}

@media (max-width: 480px) {
  .browseButton {
    font-size: 0.8rem;
  }
}
.animatedLink {
  display: inline-block;
  padding: 15px 25px;
  font-size: 18px;
  color: rgb(254, 253, 249);
  text-decoration: none;
  background-color: #007bff;
  border-radius: 5px;
  position: relative;
  overflow: hidden;
  transition: color 0.3s ease;
}

.stickySearchBar {
  position: fixed;
  top: 0;
  left: 150px;
  width: 90%;
  /* box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1); */
  padding: 10px 20px;
  z-index: 1000;
  display: flex;
  justify-content: flex-start;
  transition: transform 0.3s ease-in-out;
}

@media screen and (max-width: 768px) {
  .stickySearchBar {
    left: 0;
    width: 100%;
    top: 59px;
    /* padding: 5px 8px; */
    justify-content: flex-start;
    background-color: rgb(254, 253, 249);
  }

  .stickySearchBar .searchLink {
    font-size: 14px;
  }
}

.searchLink1 {
  flex-grow: 1;
  height: 70%;
  margin-right: 494px;
  width: 60%;

  @media (--viewportLarge) {
    padding-left: 0;
  }

  @media screen and (max-width: 768px) {
    position: relative;
    height: 100%;
    margin-right: 0;
    /* margin-top: 50px; */
    align-items: center;
    justify-content: center;
  }
}

.animatedLink::before {
  content: '';
  position: absolute;
  top: 0;
  left: -100%;
  width: 100%;
  height: 100%;
  background: linear-gradient(120deg, #ff0077, #007bff, #00ff99);
  transition: all 0.4s;
  z-index: -1;
  animation: animateBackground 3s linear infinite;
}

.animatedLink:hover {
  color: #007bff;
}

.animatedLink:hover::before {
  left: 0;
}

@keyframes animateBackground {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}
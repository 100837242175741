/* Wrapper to control visibility and layout */
.mainWrapper{
    /* margin-top: 80px; */
}
.heading{
    font-weight: bolder;
    text-align: center;
    color: var(--colorGrey900);
}
.carouselWrapper {
    width: 100%;
    overflow: hidden;
    position: relative;
    /* height: 200px;  */
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  /* Scrolling track containing the images */
  .carouselTrack {
    display: flex;
    /* gap: 20px;  */
    will-change: transform; /* Optimize for smooth animations */
    transform: translateX(0);
  }
  
  /* Individual sponsor items */
  .sponsorItem {
    flex-shrink: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    width: calc(20% - 10px); /* Adjusted for 5 images per row */
    margin: 5px; /* Spacing between items */
    box-sizing: border-box; /* Ensures padding and border are included in the width */
  }
  
  /* Images inside sponsor items */
  .sponsorImage {
    max-width: 100%; /* Ensures image stays within the container */
    max-height: 250px; /* Increased height for larger logos */
    object-fit: contain; /* Ensures logos aren't cropped */
  }
  
  
.slideshowWrapper {
    width: 100%;
    height: 150px; /* Adjust based on image size */
    overflow: hidden;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  /* Slideshow track to hold the slides */
  .slideshowTrack {
    display: flex;
    position: relative;
    width: 100%;
    height: 100%;
  }
  
  /* Individual slide */
  .slide {
    position: absolute;
    opacity: 0;
    transition: opacity 1s ease-in-out, transform 1s ease-in-out;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .slideImage {
    max-width: 150px;
    max-height: 150px;
    object-fit: contain;
  }
  
  /* Active slide styling */
  .active {
    opacity: 1;
    transform: translateX(0);
    z-index: 2;
  }
  
  /* Inactive slides (hidden) */
  .inactive {
    opacity: 0;
    transform: translateX(100%);
    z-index: 1;
  }
  